<template>
  <NuxtLoadingIndicator color="#2955FF" />
  <div class="flex flex-col justify-between h-screen">
    <ClientOnly>
      <LazyNavigation v-if="!isMobile" />
      <LazyMobileNavigation v-else />
    </ClientOnly>

    <main
      id="app"
      class="relative w-full mx-auto mb-auto"
      style="max-width: 2560px"
    >
      <slot />
    </main>

    <span id="active-bottom"></span>

    <LazyNewsletter v-if="!isStorePage && footerEnable" />

    <LazyCuratorWidget v-if="isCuratorVisible && curatorEnable" />

    <ClientOnly>
      <LazyFooter v-if="!isStorePage && footerEnable" />
    </ClientOnly>

    <component :is="ModalsContainer" v-if="ModalsContainer" />
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";
import { useLocaleStore } from "@/store/locales";

// use methods
const route = useRoute();
const mainStore = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();

//data
const $i18nPath = nuxtApp.$i18nPath;
const isMobile = ref(false);
const curatorEnable = ref(false);
const footerEnable = ref(false);
const windowWidth = ref(0);
const ModalsContainer = shallowRef(null);

onServerPrefetch(async () => {
  // ✅ this will work
  await mainStore.fetchInitial();
});

// computed
const isStorePage = computed(() => {
  return route.path.startsWith($i18nPath("/stores"));
});

const isAboutUsPage = computed(() => {
  return route.path.startsWith($i18nPath("/about-us"));
});

const isHomePage = computed(() => {
  return route.name == "country";
});

const isCuratorVisible = computed(() => {
  return isHomePage.value;
});

mainStore.loadMenu();
localeStore.fetchLocales();

onMounted(async () => {
  if (!process.browser) return false;
  window.onscroll = () => {
    if (document.documentElement.scrollTop > 10) {
      if (!curatorEnable.value) {
        mainStore.loadFeatures();
        curatorEnable.value = true;
        footerEnable.value = true;
        window.onscroll = null;
      }
    }
  };

  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 1023) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }

  await nextTick();
  window.addEventListener("resize", onResize);
  const initTime = setTimeout(async () => {
    const modalContainerComp = await import("vue-final-modal");
    ModalsContainer.value = modalContainerComp.ModalsContainer;
    clearTimeout(initTime);
  }, 1300);
  checkIntersection();
  const init2Time = setTimeout(() => {
    if (nuxtApp.$loadGTM) {
      nuxtApp.$loadGTM();
    }
    clearTimeout(init2Time);
  }, 10000);
  const init3Time = setTimeout(() => {
    if (nuxtApp.$loadIubendaCookieConsent) {
      nuxtApp.$loadIubendaCookieConsent();
    }
    if (!isStorePage.value && nuxtApp.$loadIntercom) {
      nuxtApp.$loadIntercom();
    }
    clearTimeout(init3Time);
  }, 6000);
  const countryLocale = useCookie("CountryLocale", {
    path: "/",
    watch: "shallow",
  });
  countryLocale.value = `${mainStore.country}/${mainStore.locale}`;
});

onBeforeUnmount(() => {
  window.onscroll = null;
  window.removeEventListener("resize", onResize);
});

const onResize = () => {
  windowWidth.value = window.innerWidth;
  if (windowWidth.value <= 1023) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
};

function checkIntersection(item) {
  if (process.browser) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!footerEnable.value) {
            footerEnable.value = true;
          }
        }
      });
    });

    observer.observe(document.getElementById("active-bottom"));
  }
}

/***  head setup ***/
const baseAppUrl = nuxtApp.$config.public.APP_URL;
const styles = [];

// eslint-disable-next-line
let metaLinks = [
  { rel: "alternate", href: `${baseAppUrl}`, hreflang: "x-default" },
  {
    rel: "canonical",
    href: `${baseAppUrl}${route.path.substring(1)}`,
  },
];

if (mainStore.country === "gr") {
  styles.push({
    type: "text/css",
    cssText: `
        .font-sans { font-family: 'Roboto', 'Ubuntu', 'Calibri','Arial','"Open Sans"', 'sans-serif';}
        .font-condensed { font-family: 'Roboto', 'Ubuntu', 'Calibri','Arial','"Open Sans"', 'sans-serif';}
    `,
  });
} else {
  metaLinks.push({
    rel: "stylesheet",
    href: "https://use.typekit.net/asq2lzn.css",
  });
}

// Add alternate urls
mainStore.sites
  .filter((site) => site.slug === mainStore.country)
  .forEach((site) => {
    site.urls.forEach((item) => {
      metaLinks.push({
        rel: "alternate",
        href: item.url,
        hreflang: item.locale_code,
      });
    });
  });

useHead({
  titleTemplate: "%s |  E-ZI",
  link: metaLinks,
  style: styles,
  htmlAttrs: {
    lang: mainStore.locale,
    dir: mainStore.isRtl ? "rtl" : "ltr",
  },
});
</script>

<style>
@import "@/assets/css/app.css";
@import "vue-final-modal/style.css";

html {
  @apply font-sans text-base antialiased text-gray-700;
}

a {
  @apply text-gray-900 no-underline;
}

a:hover {
  @apply text-gray-400 underline;
}
</style>
